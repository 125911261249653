import { Form, Table } from "@themesberg/react-bootstrap";
import api from "api";
import Loader from "components/common/Loader";
import { t } from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Filter from "../../Supervision/Planning/PlanningFilter";
import { FilterSupervisionPlanning } from "lib/PlanningLib";
import { monthsWithWeeks } from "utils/AnalysisContent";

const Analysis = () => {
  const [loading, setLoading] = useState(false);
  const [selector, setSelector] = useState("months");
  const [analysisData, setAnalysisData] = useState(null);
  const [dupAnalysisData, setDupAnalysisData] = useState(null);

  const [collapseRows, setCollapsRows] = useState([]);
  // Filter State
  const [filterValues, setFilterValues] = useState({});
  const [checkedMonths, setCheckedMonths] = useState([]);

  const handleSelector = (value) => {
    setSelector(value);
    setCheckedMonths([]);
  };

  const getAnalysisData = async (query) => {
    setLoading(true);
    try {
      let data = await api.get("/supervision/getAnalysis", query);
      //debugger;
      setAnalysisData(data?.data);
      setDupAnalysisData(data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCollapseRow = (buildingId) => {
    let isPresent = collapseRows.find((el) => el === buildingId);
    if (isPresent) {
      setCollapsRows(collapseRows.filter((el) => el !== buildingId));
    } else {
      setCollapsRows([...collapseRows, buildingId]);
    }
  };

  useEffect(() => {
    getAnalysisData();
  }, []);

  // Filter Code
  const handleFindClick = async () => {
    if (
      filterValues?.property_code?.length === 0 &&
      filterValues?.responsible_user?.length === 0
    ) {
      setAnalysisData(dupAnalysisData);
    } else {
      let filteredArrays = dupAnalysisData;
      if (filterValues?.property_code?.length > 0) {
        filteredArrays = dupAnalysisData?.filter((innerArray) => {
          const obj = innerArray[0];
          return filterValues?.property_code?.includes(obj?.property_code);
        });
      }
      if (filterValues?.responsible_user?.length > 0) {
        filteredArrays = filteredArrays
          .map((buildingArray) => {
            const building = buildingArray[0]; // Get the building object
            return [
              {
                ...building,
                components: building.components?.filter((comp) =>
                  filterValues.responsible_user.includes(
                    comp.responsible_user?.toUpperCase()
                  )
                ),
              },
            ];
          })
          .filter((buildingArray) => buildingArray[0].components?.length > 0); // Remove buildings with no matching components
      }
      setAnalysisData(filteredArrays);
    }
  };

  const calculateQuota = (e) => {
    //debugger;

    let months;
    if (!e.target.checked) {
      months = checkedMonths?.filter((el) => el !== e.target.name);
    } else {
      months = [...checkedMonths, e.target.name];
    }
    setCheckedMonths(months);
    if (months?.length > 0) {
      let updatedData = analysisData?.map((buildings) => {
        //debugger;
        let updatedBuildings = buildings?.map((b) => {
          //debugger;
          let updatedComps = b?.components?.map((component) => {
            if (
              component?.component_code === "CL4580" &&
              b?.building_code === "100501"
            ) {
              //debugger;
            }
            let {
              attendance_interval_value,
              attendance_interval_unit,
              maintenance_interval_value,
              maintenance_interval_unit,
            } = component;
            let registeredT = 0;
            let registeredS = 0;
            months?.map((m) => {
              //debugger;
              component[m]?.map((value) => {
                if (value === "T") {
                  registeredT = registeredT + 1;
                } else {
                  registeredS = registeredS + 1;
                }
              });
            });

            let plannedT = 0;
            let plannedS = 0;
            if (
              months?.length >= parseInt(attendance_interval_value) &&
              attendance_interval_unit == "M"
            ) {
              plannedT = Math.round(
                months?.length / parseInt(attendance_interval_value)
              );
            } else {
              registeredT = 0;
            }
            if (
              months?.length >= parseInt(maintenance_interval_value) &&
              maintenance_interval_unit == "M"
            ) {
              plannedS = Math.round(
                months?.length / parseInt(maintenance_interval_value)
              );
            } else {
              registeredS = 0;
            }

            let percent = Math.round(
              ((registeredT + registeredS) / (plannedT + plannedS)) * 100
            );
            if (percent > 100) {
              percent = 100;
            }
            component.percent = percent || 0;

            return component;
          });
          return {
            ...b,
            components: updatedComps,
          };
        });
        return updatedBuildings;
      });
      setAnalysisData(updatedData);
    }
  };

  return loading ? (
    <div style={{ marginBottom: "1rem" }}>
      <Loader />
    </div>
  ) : (
    <div>
      <Filter
        handleFindClick={handleFindClick}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      <div style={{ display: "flex" }}>
        <div className="superVanalysisMain">
          {["quarters", "months"].includes(selector) ? (
            <Table bordered className="tableSuper">
              <thead>
                {selector === "quarters" && (
                  <tr className="activites_header analysisHeader">
                    <th className="fixed-column"></th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          JAN-MAR
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          APR-JUNE
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          JULY-SEP
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          OCT-DEC
                        </span>
                      </div>
                    </th>
                  </tr>
                )}
                {selector !== "quarters" && (
                  <tr className="activites_header analysisHeader">
                    <th className="fixed-column"></th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="jan"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("common.pages.Jan")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="feb"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("common.pages.Feb")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="mar"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("common.pages.Mar")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="apr"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          {t("common.pages.April")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="may"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("property_page.May")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="jun"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          {t("common.pages.June")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="jul"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          {t("common.pages.July")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="aug"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("common.pages.August")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="sep"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          {t("common.pages.September")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="oct"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>

                        <span className="supervision_property_name">
                          {t("common.pages.October")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="nov"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("common.pages.November")}
                        </span>
                      </div>
                    </th>
                    <th className="yearsColunm">
                      <div className="analysisTableHeaders">
                        <Form.Check
                          type="checkbox"
                          name="dec"
                          onChange={calculateQuota}
                          className="analysis_jan_label"
                        ></Form.Check>
                        <span className="supervision_property_name">
                          {t("common.pages.December")}
                        </span>
                      </div>
                    </th>
                  </tr>
                )}
              </thead>
              <tbody className="activites_year_table_main">
                {analysisData?.map((ad) => {
                  return ad?.map((el) => {
                    if (el?.components?.length === 0) return;
                    let isCollapsed = collapseRows?.find(
                      (build) => build === el?.building_code
                    );
                    return (
                      <>
                        <tr className="activites_start_year headerRow">
                          <td
                            className="fixed-column headerRow"
                            style={{
                              padding: "0.3rem 0.5rem",
                              fontWeight: "700",
                            }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center c-pointer"
                              onClick={() =>
                                handleCollapseRow(el?.building_code)
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <span class="material-symbols-outlined propertyIcon">
                                    home_work
                                  </span>
                                  <p className="mb-0 fWgt supervision_property_name">
                                    {el?.Property}
                                  </p>
                                </div>
                                <div className="d-flex buildingHead">
                                  <span class="material-symbols-outlined buildingIcon">
                                    home
                                  </span>
                                  <p className="mb-0 fWgt supervision_property_name">
                                    {el?.building}
                                  </p>
                                </div>
                              </div>
                              {isCollapsed ? (
                                // ..
                                <span class="material-symbols-outlined analysis_expand_icon">
                                  chevron_right
                                </span>
                              ) : (
                                <span class="material-symbols-outlined analysis_expand_icon">
                                  expand_more
                                </span>
                              )}
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          {selector !== "quarters" && (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </tr>
                        {!isCollapsed &&
                          el?.components?.map((comp) => {
                            return (
                              <>
                                <tr className="activites_start_year activites_activity analysisRow">
                                  <td className="reportYearTD fixed-column">
                                    <div className="compDataMain">
                                      <div className="compColunm">
                                        <div
                                          className="compColor"
                                          id={
                                            comp?.u_system &&
                                            comp?.u_system?.substring(0, 3)
                                          }
                                        >
                                          {/* {comp?.u_system} */}
                                        </div>

                                        <div className="compName supervision_property_name">
                                          {comp?.compCode}
                                        </div>
                                      </div>
                                      {comp?.percent !== null &&
                                        comp?.percent !== undefined &&
                                        checkedMonths?.length > 0 && (
                                          <div className="compPercent">
                                            <span>{comp?.percent}%</span>
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td className="reportYearTD text-center">
                                    <div className="d-flex">
                                      {comp?.jan?.map((val) => (
                                        <div
                                          className={`plottingValue ${`value${val}`}`}
                                        >
                                          {val}
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                  <td className="reportYearTD text-center">
                                    <div className="d-flex">
                                      {comp?.feb?.map((val) => (
                                        <div
                                          className={`plottingValue ${`value${val}`}`}
                                        >
                                          {val}
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                  <td className="reportYearTD text-center">
                                    <div className="d-flex">
                                      {comp?.mar?.map((val) => (
                                        <div
                                          className={`plottingValue ${`value${val}`}`}
                                        >
                                          {val}
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                  <td className="reportYearTD text-center">
                                    <div className="d-flex">
                                      {comp?.apr?.map((val) => (
                                        <div
                                          className={`plottingValue ${`value${val}`}`}
                                        >
                                          {val}
                                        </div>
                                      ))}
                                    </div>
                                  </td>

                                  {selector !== "quarters" && (
                                    <>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.may?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.june?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.july?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.aug?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.sep?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.oct?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.nov?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                      <td className="reportYearTD text-center">
                                        <div className="d-flex">
                                          {comp?.dec?.map((val) => (
                                            <div
                                              className={`plottingValue ${`value${val}`}`}
                                            >
                                              {val}
                                            </div>
                                          ))}
                                        </div>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              </>
                            );
                          })}
                      </>
                    );
                  });
                })}
              </tbody>
            </Table>
          ) : (
            <Table bordered className="tableSuper">
              <thead>
                <tr className="activites_header analysisHeader">
                  <th className="fixed-column"></th>
                  {monthsWithWeeks(t)?.map((el) => {
                    return (
                      <th className="yearsColunm">
                        <div className="analysisTableHeaders">
                          <Form.Check
                            type="checkbox"
                            className="analysis_jan_label"
                          ></Form.Check>

                          <span class="supervision_property_name"> {el}</span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="activites_year_table_main">
                {analysisData?.map((ad) => {
                  return ad?.map((el) => {
                    if (el?.components?.length === 0) return;
                    let isCollapsed = collapseRows?.find(
                      (build) => build === el?.building_code
                    );
                    return (
                      <>
                        <tr className="activites_start_year headerRow">
                          <td
                            className="fixed-column headerRow"
                            style={{
                              padding: "0.3rem 0.5rem",
                              fontWeight: "700",
                            }}
                          >
                            <div
                              className="d-flex justify-content-between align-items-center c-pointer"
                              onClick={() =>
                                handleCollapseRow(el?.building_code)
                              }
                            >
                              <div>
                                <div className="d-flex">
                                  <span class="material-symbols-outlined propertyIcon">
                                    home_work
                                  </span>
                                  <p className="mb-0 fWgt supervision_property_name">
                                    {el?.Property}
                                  </p>
                                </div>
                                <div className="d-flex buildingHead">
                                  <span class="material-symbols-outlined buildingIcon">
                                    home
                                  </span>
                                  <p className="mb-0 fWgt supervision_property_name">
                                    {el?.building}
                                  </p>
                                </div>
                              </div>
                              {isCollapsed ? (
                                // ...
                                <span class="material-symbols-outlined analysis_expand_icon">
                                  chevron_right
                                </span>
                              ) : (
                                <span class="material-symbols-outlined analysis_expand_icon">
                                  expand_more
                                </span>
                              )}
                            </div>
                          </td>
                          {monthsWithWeeks(t)?.map((mw) => {
                            return <td></td>;
                          })}
                        </tr>
                        {!isCollapsed &&
                          el?.components?.map((comp) => {
                            return (
                              <>
                                <tr className="activites_start_year activites_activity analysisRow">
                                  <td className="reportYearTD fixed-column">
                                    <div className="compDataMain">
                                      <div className="compColunm">
                                        <div
                                          className="compColor"
                                          id={comp?.u_system}
                                        >
                                          {/* {comp?.u_system} */}
                                        </div>

                                        <div className="compName supervision_property_name">
                                          {/* {comp?.long_name} */}
                                          {comp?.compCode}
                                        </div>
                                      </div>
                                      {comp?.percent !== null &&
                                        comp?.percent !== undefined &&
                                        checkedMonths?.length > 0 && (
                                          <div className="compPercent">
                                            <span>{comp?.percent}%</span>
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  {[1, 2, 3, 4].map((mw) => {
                                    return (
                                      <>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.jan?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.feb?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.mar?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.apr?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>

                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.may?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.june?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.july?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.aug?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.sep?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.oct?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.nov?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="reportYearTD text-center">
                                          <div className="d-flex">
                                            {comp?.dec?.map((val) => (
                                              <div
                                                className={`plottingValue ${`value${val}`}`}
                                              >
                                                {val}
                                              </div>
                                            ))}
                                          </div>
                                        </td>
                                      </>
                                    );
                                  })}
                                </tr>
                              </>
                            );
                          })}
                      </>
                    );
                  });
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>

      <div className="analysisBottom">
        <div
          className={`bottomSelector ${
            selector === "weeks" ? "activeSelector" : ""
          }`}
          onClick={() => handleSelector("weeks")}
        >
          {t("property_page.Weeks")}
        </div>
        <div className="pipeLine"></div>
        <div
          className={`bottomSelector ${
            selector === "months" ? "activeSelector" : ""
          }`}
          onClick={() => handleSelector("months")}
        >
          {t("property_page.Months")}
        </div>
        <div className="pipeLine"></div>
        <div
          className={`bottomSelector ${
            selector === "quarters" ? "activeSelector" : ""
          }`}
          onClick={() => handleSelector("quarters")}
        >
          {t("property_page.Quarters")}
        </div>
      </div>
    </div>
  );
};

export default Analysis;
